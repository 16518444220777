<template>
  <div class="bigbox">
    <div class="zhfwtop">
      <div class="indeximgtopcontent">
        <div class="indexlogo"></div>
      <div class="indexlist">
          <ul class="indexlistul">
            <li  @click="go('/home')"><span>首页</span></li>
            <li  class="indexli2">
              <span >产品解决方案</span>
                <ul class="menu">
                  <li  @click="go('/cpjjfa/zhfw')">智慧法务</li>
                  <li  @click="go('/cpjjfa/zhcg')">智慧采购</li>
                  <li  @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <li  @click="go('/cpjjfa/bgxt')">办公协同</li>
                  <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li @click="go('/jszc')"><span>技术支持</span></li>
            <li @click="go('/yhal')"><span class="active2">用户案例</span></li>
            <li  @click="go('/gywm')"><span>关于我们</span></li>
          </ul>
      </div>
      </div>

      <div class="zhfwbutton"></div>
    </div>
    <!--  -->
    <div class="diybottom">
      <div class="diybottomcontent">
        <div class="dlyindece">省电力设计院能源信息统计与检测系统把各类共享信息资源的开发利用作为重点工作内容，这也是电子化工程的重点方向。项目建设通过统一规划、共同开发，协同服务，既可以盘活数据资源，减少部门信息重复采集建设、节省人力成本、提高信息利用率和时效性，产生直接经济效益，为各部门的业务系统的建设提供宏观指导作用的建设方案，直接降低建设成本。</div>
        <div class="dlybottom1"></div>
        <div class="dlybottom2"></div>
      </div>
    </div>
    <!--  -->
    <tabar-bottom></tabar-bottom>
  </div>
</template>

<script>
import TabarBottom from '../../../components/TabarBottom.vue'
  export default {
    components:{ TabarBottom },
    methods:{
      go(path){
        this.$router.push({
          path:path
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.indeximgtopcontent{
  width: 1223px;
  height: 46px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}
//
.diybottom{
  width: 100%;
  height: 1713px;
  margin: 0 auto;
  .diybottomcontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
    padding-top: 59px;
    .dlyindece{
      text-indent: 2rem;
      width: 1200px;
      height: 80px;
      font-size: 18px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #282A2E;
    }
    .dlybottom1{
      width: 1200px;
      height: 730px;
      background: url('../../.././assets/dlybottom1.png') no-repeat center;
      background-size: 1200px 730px;
      margin-top: 40px;
    }
    .dlybottom2{
      width: 1200px;
      height: 674px;
      background: url('../../.././assets/dlybottom2.png') no-repeat center;
      background-size: 1200px 674px;
      margin-top: 40px;
    }
  }
}
//
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
}
.active2{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.zhfwtop{
  // width: 1903px;
  height: 500px;
  background: url('../../.././assets/yhal_diyback.png') no-repeat center 0;
  margin: 0 auto;
  padding-top: 34px;
  .indexlogo{
  width: 220px;
  height: 52px;
  background: url('../../.././assets/logo.png') no-repeat center center;
}
.zhfwbutton{
  float: left;
  width: 373px;
  height: 175px;
  margin-left: 774px;
  margin-top: 90px;
}
.zhfwindc{
  text-align: center;
  float: left;
  width: 1100px;
  height: 51px;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 432px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.indexlist{
  width: 632px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;

  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
}
//
.menu{
  position: absolute;
  display: none;
  top: 45px;
  background: #fff;
  color: #282A2E;
  padding-left: 20px;
  padding-left: 20px;
  text-align: left;
  left: 16px;
}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
}
</style>
